import {
  PatientTransactionItemType,
  TransactionItemSubtypeEnum,
  TransactionItemTypeEnum,
  TransactionTypeEnum,
} from '../types';
import { findNewDiscount } from './findNewDiscount';

export const subtotalFromItemsBeforePercentageDiscounts = ({
  items,
  taxRate,
}: {
  items: PatientTransactionItemType[];
  taxRate: number;
}) => {
  if (!items || !Array.isArray(items) || items.length === 0) return 0;
  return (items || []).reduce((acc, curr) => {
    if (curr?.type === TransactionItemTypeEnum.Void) {
      return acc;
    }

    const amount =
      typeof curr?.amount === 'string'
        ? Number.parseFloat(curr?.amount)
        : curr?.amount || 0;
    const quantity = curr?.units || 0;
    const total = amount * quantity;
    const priceIncludesTax = curr?.priceIncludesTax;
    if (curr?.type === TransactionItemTypeEnum.Debit && !priceIncludesTax) {
      return acc + total;
    } else if (
      curr?.type === TransactionItemTypeEnum.Debit &&
      priceIncludesTax
    ) {
      const newTotal = total / (1 + taxRate / 100);
      return acc + newTotal;
    } else if (
      curr?.type === TransactionItemTypeEnum.Credit &&
      curr?.subtype === TransactionItemSubtypeEnum.Adjustment &&
      curr?.adjustment?.structure !== '% Discount'
    ) {
      return acc - total;
    }

    return acc;
  }, 0);
};

// export const taxForTransactionItem = ({
//   item,
//   productTaxRate = 0,
//   serviceTaxRate = 0,
// }: {
//   item: PatientTransactionItemType;
//   productTaxRate?: number;
//   serviceTaxRate?: number;
// }) => {
//   const isProduct = TransactionItemSubtypeEnum.Supplement === item?.subtype;
//   const isService =
//     TransactionItemSubtypeEnum.Service === item?.subtype ||
//     TransactionItemSubtypeEnum.Treatment === item?.subtype;
//   const taxRate = item.priceIncludesTax
//     ? 0
//     : (isService ? serviceTaxRate : isProduct ? productTaxRate : 0) / 100;
//   const price = Number(item?.amount || 0);
//   const quantity = item?.units || 0;
//   const totalPrice = price * quantity;
//   const total = taxRate * totalPrice;
//   return total;
// };

export const subtotalAndTaxFromItems = ({
  items,
  productTaxRate = 0,
  serviceTaxRate = 0,
  makeRateZero = false,
}: {
  items: PatientTransactionItemType[];
  productTaxRate?: number;
  serviceTaxRate?: number;
  makeRateZero?: boolean;
}): {
  subtotal: number;
  tax: number;
  total: number;
  balance: number;
} => {
  const percentAdjustment = items?.find((item) => {
    let theAdjustment = item.adjustment;
    if (theAdjustment && typeof theAdjustment === 'string') {
      theAdjustment = JSON.parse(theAdjustment);
    }
    return !!theAdjustment?.percentOff;
  });
  if (percentAdjustment && typeof percentAdjustment.adjustment === 'string') {
    percentAdjustment.adjustment = JSON.parse(percentAdjustment.adjustment);
  }
  const percentAdjustmentExists = !!percentAdjustment;

  let itemsWithNoTax = items.filter(
    (item) => item.subtype !== TransactionItemSubtypeEnum.Tax,
  );
  if (percentAdjustmentExists) {
    itemsWithNoTax = findNewDiscount(
      itemsWithNoTax,
      serviceTaxRate,
      productTaxRate,
    );
  }

  // If there is a treatment on a transaction, we're using the service tax rate, otherwise we're using the product tax rate
  const hasTreatment = itemsWithNoTax.find(
    (item) =>
      item?.subtype === TransactionItemSubtypeEnum.Treatment ||
      item?.subtype === TransactionItemSubtypeEnum.Service,
  );

  const taxRate = makeRateZero
    ? 0
    : hasTreatment
      ? serviceTaxRate
      : productTaxRate;

  if (
    !itemsWithNoTax ||
    !Array.isArray(itemsWithNoTax) ||
    itemsWithNoTax.length === 0
  ) {
    return {
      subtotal: 0,
      tax: 0,
      total: 0,
      balance: 0,
    };
  }

  const paymentTotal = (items || []).reduce((acc, curr) => {
    // No need to explicitly handle voids here unless changes are made in the future.
    if (
      curr?.type === TransactionItemTypeEnum.Credit &&
      curr?.subtype !== TransactionItemSubtypeEnum.Adjustment &&
      curr?.subtype !== TransactionItemSubtypeEnum.Override
    ) {
      return acc + Number(curr?.amount || 0);
    } else if (
      curr?.type === TransactionItemTypeEnum.Debit &&
      curr?.subtype === TransactionItemSubtypeEnum.PatientRefund
    ) {
      return acc - Number(curr?.amount || 0);
    }
    return acc;
  }, 0);

  const override = itemsWithNoTax.find(
    (item) =>
      item?.type === TransactionItemTypeEnum.Debit &&
      item?.subtype === TransactionItemSubtypeEnum.Override,
  );
  if (override) {
    const subtotal = Number(override?.amount) || 0;
    const tax = subtotal * (taxRate / 100);
    const total = (Number(override?.amount) || 0) + tax;
    const balance = total - paymentTotal;

    return {
      subtotal,
      tax,
      total,
      balance,
    };
  }

  const { subtotal, itemsTotal } = (itemsWithNoTax || []).reduce(
    ({ subtotal, itemsTotal }, curr) => {
      if (curr?.type !== TransactionItemTypeEnum.Void) {
        // Subtotal stuff
        const amount =
          typeof curr?.amount === 'string'
            ? Number.parseFloat(curr?.amount)
            : curr?.amount || 0;
        const quantity = curr?.units || 0;
        const total = amount * quantity;

        if (
          curr?.type === TransactionItemTypeEnum.Debit &&
          curr?.subtype !== TransactionItemSubtypeEnum.PatientRefund &&
          curr?.subtype !== TransactionItemSubtypeEnum.Tax
        ) {
          const priceIncludesTax = curr?.priceIncludesTax;
          if (curr?.salesTax === true && !priceIncludesTax) {
            subtotal = subtotal + total;
            itemsTotal = itemsTotal + total;
          } else if (priceIncludesTax) {
            const rate = hasTreatment ? serviceTaxRate : productTaxRate;
            const subtotalAmount = total / (1 + rate / 100);
            subtotal = subtotalAmount + subtotal;
            itemsTotal = subtotalAmount + itemsTotal;
          } else {
            subtotal = subtotal + total;
            itemsTotal = itemsTotal + total;
          }
        } else if (
          curr?.type === TransactionItemTypeEnum.Credit &&
          curr?.subtype === TransactionItemSubtypeEnum.Adjustment
        ) {
          subtotal = subtotal - total;
        }
      }
      return {
        subtotal,
        itemsTotal,
      };
    },
    {
      subtotal: 0,
      itemsTotal: 0,
    },
  );

  const totalDollarDiscount = itemsWithNoTax.reduce((acc, curr) => {
    if (curr?.adjustment && typeof curr?.adjustment === 'string') {
      // This happens on the backend for some reason. I'm not going to look for it.
      curr.adjustment = JSON.parse(curr.adjustment);
    }
    if (
      curr?.type === TransactionItemTypeEnum.Credit &&
      curr?.subtype === TransactionItemSubtypeEnum.Adjustment &&
      curr?.adjustment?.structure === '$ Discount'
    ) {
      return acc + Number(curr?.amount || 0);
    }
    return acc;
  }, 0);

  const itemsToBuy = itemsWithNoTax.filter(
    (item) =>
      item?.type === TransactionItemTypeEnum.Debit &&
      item?.subtype !== TransactionItemSubtypeEnum.PatientRefund &&
      item?.subtype !== TransactionItemSubtypeEnum.Adjustment &&
      item?.subtype !== TransactionItemSubtypeEnum.Tax,
  );

  let discountUsedUp = 0;

  const taxForItems = makeRateZero
    ? 0
    : (itemsToBuy || []).reduce((acc, curr) => {
        const isLastItem = curr === itemsToBuy[itemsToBuy.length - 1];
        const itemTotalWithQuantity =
          Number(curr?.amount || 0) * (curr?.units || 0);
        const rate = hasTreatment ? serviceTaxRate : productTaxRate;
        const subtotalAmount = curr.priceIncludesTax
          ? itemTotalWithQuantity / (1 + rate / 100)
          : itemTotalWithQuantity;
        if (isLastItem) {
          if (!curr.salesTax) return acc;
          const discountLeft = totalDollarDiscount - discountUsedUp;
          const amountToTax = subtotalAmount - discountLeft;
          const taxForItem = taxForTransactionItem({
            item: { ...curr, amount: amountToTax },
            taxRate: hasTreatment ? serviceTaxRate : productTaxRate,
            percentAdjustment,
          });
          return acc + taxForItem;
        } else {
          const percentageOfTotal = subtotalAmount / itemsTotal;
          const totalDiscount = totalDollarDiscount * percentageOfTotal;
          discountUsedUp += totalDiscount;
          if (!curr.salesTax) return acc;
          const amountToTax = subtotalAmount - totalDiscount;
          return (
            acc +
            taxForTransactionItem({
              item: { ...curr, amount: amountToTax },
              taxRate: hasTreatment ? serviceTaxRate : productTaxRate,
              percentAdjustment,
            })
          );
        }
      }, 0);

  const finalSubtotal = round(subtotal, 2);
  const finalTax = round(taxForItems, 2);
  const total = round(finalSubtotal + finalTax, 2) || 0;
  const balance = round(total - paymentTotal, 2);

  return {
    subtotal: finalSubtotal,
    tax: finalTax,
    total,
    balance,
  };
};

export const balanceFromItems = ({
  items,
  productTaxRate,
  serviceTaxRate,
  startingBalance,
  transactionType,
}: {
  startingBalance: number | undefined;
  items: PatientTransactionItemType[];
  productTaxRate?: number;
  serviceTaxRate?: number;
  transactionType: TransactionTypeEnum;
}): {
  balance: number;
  tax: number;
  total?: number;
} => {
  if (!items?.length) return { balance: startingBalance || 0, tax: 0 };

  const { balance, tax, total } = subtotalAndTaxFromItems({
    items,
    productTaxRate,
    serviceTaxRate,
    makeRateZero: !items?.some((item) => item?.salesTax === true),
  });

  if (balance === 0) {
    return {
      balance: balance || 0,
      tax: tax || 0,
      total,
    };
  }

  startingBalance = startingBalance || 0;

  // if (startingBalance !== 0) {
  //   return { balance: startingBalance + balance, tax };
  // }

  if (transactionType === TransactionTypeEnum.Payment) {
    return { balance: -balance, tax: 0, total: 0 };
  }

  return {
    balance,
    tax,
    total: typeof total === 'number' && !isNaN(total) ? total : 0,
  };
};

export const round = (num: number, places: number) => {
  const multiplier = Math.pow(10, places);
  return Math.round(num * multiplier) / multiplier;
};

const taxForTransactionItem = ({
  item,
  taxRate = 0,
  percentAdjustment,
}: {
  item: PatientTransactionItemType;
  taxRate?: number;
  percentAdjustment?: PatientTransactionItemType;
}) => {
  console.log({ item, taxRate });
  const chargedAmount = percentAdjustment?.adjustment?.percentOff
    ? Number(item?.amount || 0) *
      (1 - percentAdjustment?.adjustment?.percentOff / 100)
    : item?.amount;
  const taxRateToUse = (taxRate || 0) / 100;
  const price = Number(chargedAmount || 0);
  const taxToCharge = taxRateToUse * price;
  return taxToCharge;
};
