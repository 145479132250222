import dayjs, { Dayjs } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { createDayjs } from './time';

dayjs.extend(utc);
dayjs.extend(tz);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export type Override = { date: string; start: string; end: string };

export type Overrides = Override[];

export type AvailabilityForDay = {
  start: string;
  end: string;
  locations?: number[];
};

export type Availability = {
  Sunday: AvailabilityForDay[];
  Monday: AvailabilityForDay[];
  Tuesday: AvailabilityForDay[];
  Wednesday: AvailabilityForDay[];
  Thursday: AvailabilityForDay[];
  Friday: AvailabilityForDay[];
  Saturday: AvailabilityForDay[];
};

export type DayOfWeek = keyof Availability;

export type ScheduleAvailability = {
  availableDays: {
    // day of the month
    [key: number]: {
      // user id
      [key: number]: string[];
    };
  };
  allAvailableDays?: {
    // day of the month
    [key: number]: { user: number; time: string }[];
  };
  users: any[];
};

export const times = [
  { text: 'None', value: '' },
  { text: '6:00 AM', value: '06:00:00' },
  { text: '6:15 AM', value: '06:15:00' },
  { text: '6:30 AM', value: '06:30:00' },
  { text: '6:45 AM', value: '06:45:00' },
  { text: '7:00 AM', value: '07:00:00' },
  { text: '7:15 AM', value: '07:15:00' },
  { text: '7:30 AM', value: '07:30:00' },
  { text: '7:45 AM', value: '07:45:00' },
  { text: '8:00 AM', value: '08:00:00' },
  { text: '8:15 AM', value: '08:15:00' },
  { text: '8:30 AM', value: '08:30:00' },
  { text: '8:45 AM', value: '08:45:00' },
  { text: '9:00 AM', value: '09:00:00' },
  { text: '9:15 AM', value: '09:15:00' },
  { text: '9:30 AM', value: '09:30:00' },
  { text: '9:45 AM', value: '09:45:00' },
  { text: '10:00 AM', value: '10:00:00' },
  { text: '10:15 AM', value: '10:15:00' },
  { text: '10:30 AM', value: '10:30:00' },
  { text: '10:45 AM', value: '10:45:00' },
  { text: '11:00 AM', value: '11:00:00' },
  { text: '11:15 AM', value: '11:15:00' },
  { text: '11:30 AM', value: '11:30:00' },
  { text: '11:45 AM', value: '11:45:00' },
  { text: '12:00 PM', value: '12:00:00' },
  { text: '12:15 PM', value: '12:15:00' },
  { text: '12:30 PM', value: '12:30:00' },
  { text: '12:45 PM', value: '12:45:00' },
  { text: '1:00 PM', value: '13:00:00' },
  { text: '1:15 PM', value: '13:15:00' },
  { text: '1:30 PM', value: '13:30:00' },
  { text: '1:45 PM', value: '13:45:00' },
  { text: '2:00 PM', value: '14:00:00' },
  { text: '2:15 PM', value: '14:15:00' },
  { text: '2:30 PM', value: '14:30:00' },
  { text: '2:45 PM', value: '14:45:00' },
  { text: '3:00 PM', value: '15:00:00' },
  { text: '3:15 PM', value: '15:15:00' },
  { text: '3:30 PM', value: '15:30:00' },
  { text: '3:45 PM', value: '15:45:00' },
  { text: '4:00 PM', value: '16:00:00' },
  { text: '4:15 PM', value: '16:15:00' },
  { text: '4:30 PM', value: '16:30:00' },
  { text: '4:45 PM', value: '16:45:00' },
  { text: '5:00 PM', value: '17:00:00' },
  { text: '5:15 PM', value: '17:15:00' },
  { text: '5:30 PM', value: '17:30:00' },
  { text: '5:45 PM', value: '17:45:00' },
  { text: '6:00 PM', value: '18:00:00' },
  { text: '6:15 PM', value: '18:15:00' },
  { text: '6:30 PM', value: '18:30:00' },
  { text: '6:45 PM', value: '18:45:00' },
  { text: '7:00 PM', value: '19:00:00' },
  { text: '7:15 PM', value: '19:15:00' },
  { text: '7:30 PM', value: '19:30:00' },
  { text: '7:45 PM', value: '19:45:00' },
  { text: '8:00 PM', value: '20:00:00' },
  { text: '8:15 PM', value: '20:15:00' },
  { text: '8:30 PM', value: '20:30:00' },
  { text: '8:45 PM', value: '20:45:00' },
  { text: '9:00 PM', value: '21:00:00' },
  { text: '9:15 PM', value: '21:15:00' },
  { text: '9:30 PM', value: '21:30:00' },
  { text: '9:45 PM', value: '21:45:00' },
  { text: '10:00 PM', value: '22:00:00' },
  { text: '10:15 PM', value: '22:15:00' },
  { text: '10:30 PM', value: '22:30:00' },
  { text: '10:45 PM', value: '22:45:00' },
  { text: '11:00 PM', value: '23:00:00' },
  { text: '11:15 PM', value: '23:15:00' },
  { text: '11:30 PM', value: '23:30:00' },
  { text: '11:45 PM', value: '23:45:00' },
  { text: '12:00 AM', value: '00:00:00' },
  { text: '12:15 AM', value: '00:15:00' },
  { text: '12:30 AM', value: '00:30:00' },
  { text: '12:45 AM', value: '00:45:00' },
  { text: '1:00 AM', value: '01:00:00' },
  { text: '1:15 AM', value: '01:15:00' },
  { text: '1:30 AM', value: '01:30:00' },
  { text: '1:45 AM', value: '01:45:00' },
  { text: '2:00 AM', value: '02:00:00' },
  { text: '2:15 AM', value: '02:15:00' },
  { text: '2:30 AM', value: '02:30:00' },
  { text: '2:45 AM', value: '02:45:00' },
  { text: '3:00 AM', value: '03:00:00' },
  { text: '3:15 AM', value: '03:15:00' },
  { text: '3:30 AM', value: '03:30:00' },
  { text: '3:45 AM', value: '03:45:00' },
  { text: '4:00 AM', value: '04:00:00' },
  { text: '4:15 AM', value: '04:15:00' },
  { text: '4:30 AM', value: '04:30:00' },
  { text: '4:45 AM', value: '04:45:00' },
  { text: '5:00 AM', value: '05:00:00' },
  { text: '5:15 AM', value: '05:15:00' },
  { text: '5:30 AM', value: '05:30:00' },
  { text: '5:45 AM', value: '05:45:00' },
];

export const timeValues = times.map((time) => time.value);

export const timesHash = times.reduce(
  (obj: { [key: string]: { text: string; value: string } }, time) => {
    obj[time.value] = time;
    return obj;
  },
  {},
);

export const days: DayOfWeek[] = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const possibleStartTimesGivenAvailability = (
  timezone: string,
  month: number,
  day: number,
  year: number,
  availabilityForDay: { start: string; end: string }[],
  overrides: { date: string; start: string; end: string }[],
  takenTimes: [string, string][],
  lengthInMinutes: number,
  earliestStartTime: number,
  noOverlap = false,
) => {
  if (!availabilityForDay.length) {
    return [];
  }

  let availableTimes = timeValues.filter((time) => {
    const appointmentStart =
      createDayjs({
        datetime: `${year}-${month}-${day}T${time}`,
        timezone,
      })?.valueOf() || 0;
    if (appointmentStart <= earliestStartTime) {
      return false;
    }

    const appointmentEnd = appointmentStart + 60 * lengthInMinutes;
    const isWithinAvailability = availabilityForDay.some(({ start, end }) => {
      const startOfAvailability =
        createDayjs({
          datetime: `${year}-${month}-${day}T${start}`,
          timezone,
        })
          ?.subtract(1, 'minute')
          ?.valueOf() || 0;
      const endOfAvailability =
        createDayjs({
          datetime: `${year}-${month}-${day}T${end}`,
          timezone,
        })
          ?.add(1, 'minute')
          ?.valueOf() || 0;

      return (
        appointmentStart > startOfAvailability &&
        appointmentEnd < endOfAvailability
      );
    });

    if (!isWithinAvailability) {
      return false;
    }

    const appointmentDayjsStart = createDayjs({
      datetime: `${year}-${month}-${day}T${time}`,
      timezone,
    });
    const appointmentDayjsEnd = appointmentDayjsStart?.add(
      lengthInMinutes,
      'minutes',
    );

    const isOutsideOverrides =
      !overrides?.length ||
      overrides.every((override) => {
        const overrideStart = createDayjs({
          datetime: `${year}-${month}-${day}T${override.start}`,
          timezone,
        });
        const overrideEnd = createDayjs({
          datetime: `${year}-${month}-${day}T${override.end}`,
          timezone,
        });

        const appointmentEndsBeforeOverride =
          appointmentDayjsEnd?.isBefore(overrideStart);
        const appointmentStartsAfterOverride =
          appointmentDayjsStart?.isAfter(overrideEnd);

        return appointmentEndsBeforeOverride || appointmentStartsAfterOverride;
      });

    if (!isOutsideOverrides) {
      return false;
    }
    const isOutsideTakenTimes =
      !takenTimes?.length ||
      takenTimes.every(([takenStartTime, takenEndTime]) => {
        const takenStart = createDayjs({
          datetime: `${year}-${month}-${day}T${takenStartTime}`,
          timezone,
        });
        const takenEnd = createDayjs({
          datetime: `${year}-${month}-${day}T${takenEndTime}`,
          timezone,
        });

        // `isSameOr` allows us to have overlapping bookings by 1 minute and that's fine...so that appointments can be scheduled for 30 minutes back to back and not overlap.
        const appointmentEndsBeforeTaken =
          appointmentDayjsEnd?.isSameOrBefore(takenStart);
        const appointmentStartsAfterTaken =
          appointmentDayjsStart?.isSameOrAfter(takenEnd);

        return appointmentEndsBeforeTaken || appointmentStartsAfterTaken;
      });

    return isOutsideTakenTimes;
  });
  if (noOverlap) {
    availableTimes = availableTimes.reduce((acc: string[], time) => {
      const appointmentDayjsStart = createDayjs({
        datetime: `${year}-${month}-${day} ${time}`,
        timezone,
      });
      for (const nonOverlapTime of acc) {
        const previousStart = createDayjs({
          datetime: `${year}-${month}-${day} ${nonOverlapTime}`,
          timezone,
        });
        const previousEnd = previousStart?.add(lengthInMinutes, 'minutes');
        if (appointmentDayjsStart?.isBefore(previousEnd)) {
          return acc;
        }
      }
      return [...acc, time];
    }, []);
  }

  return availableTimes;
};

export const createHashOfDaysOfMonthWithUsersWithAvailableStartTimes = (
  timezone: string,
  startDate: Dayjs, // timezoned
  endDate: Dayjs, // timezoned
  availability: {
    // userId
    [key: string]: {
      // day
      [key: string]: {
        start: string;
        end: string;
      }[];
    };
  },
  overrides: { [key: string]: { date: string; start: string; end: string }[] },
  takenTimes: { [key: string]: { start: Dayjs; end: Dayjs }[] }, // timezoned
  lengthInMinutes: number,
  startBuffer = 0,
  noOverlap = false,
): {
  // day of the month
  [key: number]: {
    // user id
    [key: string]: {
      name: string;
      date: string;
      startTime: number;
      startTimeDisplay: string;
      endTimeDisplay: string;
      duration: number;
    }[];
  };
} => {
  const earliestStartTime = dayjs()
    .tz(timezone)
    .add(startBuffer, 'hour')
    .valueOf();
  const daysArray: Dayjs[] = [];
  let currentDate = startDate;
  while (currentDate.isBefore(endDate)) {
    daysArray.push(currentDate);
    currentDate = currentDate.add(1, 'day');
  }
  daysArray.push(endDate);

  // These will be in the timezone...
  const overridesByUserAndDayHash = Object.entries(overrides).reduce(
    (
      obj: {
        [key: string]: {
          [key: string]: {
            start: string;
            end: string;
            date: string;
          }[];
        };
      },
      [userId, overridesArr],
    ) => {
      obj[userId] = {};
      overridesArr.forEach((override) => {
        obj[userId][override.date] = obj[userId][override.date] || [];
        obj[userId][override.date].push({
          start: override.start,
          end: override.end,
          date: override.date,
        });
      });
      return obj;
    },
    {},
  );

  // These will be UTC...
  const takenTimesByUserAndDayHash = Object.entries(takenTimes).reduce(
    (obj: any, [userId, takenTimesArr]) => {
      obj[userId] = {};
      takenTimesArr.forEach((takenTime) => {
        const takenTimeDate = takenTime.start?.format('YYYY-MM-DD') || '';
        const takenTimeStart = takenTime.start?.format('HH:mm:ss');
        const takenTimeEnd = takenTime.end?.format('HH:mm:ss');
        obj[userId][takenTimeDate] = obj[userId][takenTimeDate] || [];
        obj[userId][takenTimeDate].push([takenTimeStart, takenTimeEnd]);
      });
      return obj;
    },
    {},
  );

  const userIds = Object.keys(availability);
  return daysArray.reduce((obj: any, day) => {
    const dayAsString = day.format('YYYY-MM-DD');
    const month = day.month() + 1;
    const dayOfMonth = day.date();
    const year = day.year();
    obj[dayAsString] = {};
    const dayString = dayAsString;
    const dayOfTheWeek = days[day.day()];

    userIds.forEach((userId) => {
      const dayOfTheWeekAvailability =
        availability[userId]?.[dayOfTheWeek] || [];
      const overridesForDay = overridesByUserAndDayHash[userId]?.[dayString];
      const takenTimesForDay = takenTimesByUserAndDayHash[userId]?.[dayString];
      const times = possibleStartTimesGivenAvailability(
        timezone,
        month,
        dayOfMonth,
        year,
        dayOfTheWeekAvailability,
        overridesForDay,
        takenTimesForDay,
        lengthInMinutes,
        earliestStartTime,
        noOverlap,
      );

      obj[dayString][userId] = times.map((time) => {
        const start = createDayjs({
          datetime: `${dayString}T${time}`,
          timezone,
        });
        const end = start?.add(lengthInMinutes, 'minutes');
        return {
          provider: userId,
          date: dayString,
          startTime: start?.valueOf(),
          endTime: end?.valueOf(),
          duration: lengthInMinutes,
          tz: timezone,
        };
      });
    });
    return obj;
  }, {});
};
