/**
 * [2024-07-03.1607 by Brian]
 *   This represents UI the implementation of a SALT button for
 *   transactions that do not already have services on them.
 *
 *   It knows when to show up in the DOM.
 *
 *   It works INSIDE CodeEncounterDiagnosisServicesWithDiagnosis.tsx.
 *
 *   The rules say this is _only_ available from the transaction but
 *   I don't believe that will last if the past predicts the future.
 *   I believe this is the easiest way to integrate it into the
 *   component above no matter where it is used. Hopefully, the
 *   callback technique will be enough to handle any scenario.
 */
import React, { useContext, useState } from 'react';
import { MeContext } from '../../../../../../contexts/me.context';
import {
  PatientTransaction,
  TransactionTypeEnum,
  UserRoles,
} from '@chiroup/core';
import { Button, icons, MakeBrowserWait } from '@chiroup/components';
import patientBillingService from '../../../../../../services/patientBilling.service';
import {
  ToastContext,
  ToastTypes,
} from '../../../../../../contexts/toast.context';

type Props = {
  target?: PatientTransaction;
  parentIsa?: string;
  callback?: (transaction: PatientTransaction) => void;
};

const SaltTransaction: React.FC<Props> = ({ target, parentIsa, callback }) => {
  const { createToast } = useContext(ToastContext);
  if (!callback) {
    callback = (t) => {
      console.log({
        SaltTransaction: { message: 'No callback provided.', transaction: t },
      });
    };
  }
  const { me, hasAccess, hasRole } = useContext(MeContext);
  const [isSeasoning, setSeasoning] = useState(false);

  const available = !!(
    (
      hasAccess('ehrNotes') &&
      hasRole([UserRoles.Admin, UserRoles.Biller, UserRoles.Provider]) &&
      target &&
      target.billingKey &&
      target.services?.length === 0 &&
      target.type === TransactionTypeEnum.Hybrid &&
      parentIsa === 'transaction'
    ) // Per Emily, only available from the transaction editor.
  );

  if (!available) return null;

  return (
    <div className="cursor-pointer flex flex-grow justify-end">
      <Button
        text="SALT"
        onClick={async () => {
          setSeasoning(true);
          try {
            const resp = await patientBillingService.saltPatientTransaction({
              clinicId: me?.selectedClinic?.ID,
              target: target.billingKey,
            });
            if (typeof callback === 'function') callback(resp);
          } catch (e: any) {
            // api logs the error, no repeats.
            let description = 'Failed to SALT transaction.';
            try {
              description =
                e?.response?.data?.error ??
                e?.response?.data?.message ??
                description;
            } catch (e) {
              // just don't die.
            }
            createToast({
              title: 'Error!',
              description,
              type: ToastTypes.Fail,
              duration: 5000,
            });
          }
          setSeasoning(false);
        }}
        disabled={isSeasoning}
        loading={isSeasoning}
        icon={icons.saltShaker}
        tooltip={[
          'Same as Last Time: Imports the services and diagnosis from the',
          'most-recent purchase transaction.',
          'Only available when this transaction has no existing services.',
        ].join(' ')}
      />
      <MakeBrowserWait isWaiting={isSeasoning} />
    </div>
  );
};

export default SaltTransaction;

/*

I. User Story

1) As an EHR user, I expect the ability to select "SALT" Billing Codes on a Purchase, 
   which will trigger the system to auto-import the Billing Codes from the prior 
   Purchase/Encounter for that patient.

II. Additional Information

  SALT = Same As Last Time
  Users: EHR
  User Roles: Admin, Biller, Provider
  Feature: Add a SALT button to Treatment Purchases
  Feature rules:
    Selecting the SALT button will auto-import Billing Codes from 
    the prior Purchase to the current Purchase;
    
    Only available for Treatment Purchases - or Purchases that are
    eligible for Billing Codes;
    
    This should function like the SALT button in the Plan wizard,
    except for Billing Codes

III. Acceptance Criteria

  GIVEN I am an EHR user with an Admin, Biller, or Provide role
  WHEN I access a Treatment Purchase for a Patient
  THEN I expect there to be a SALT button for Billing Codes

  IF I select the SALT button

  THEN I expect the system will look at the prior Treatment 
  Purchase for the patient AND auto-import the Billing Codes 
  saved to that Purchase to the current Purchase

IV. Requirements

  Make sure users can SALT Billing Codes from a prior Treatment 
  Purchase so they populate on the Current Purchase so users can 
  save time entering charges if the provider has not yet submitted them.

Replies:

  1) Yes, this should be a one-time thing. If there are Services 
     already on the Purchase, then they should not be able to 
     SALT, but if there are no Services, then they can SALT.

  2) Yes, it should include both Diagnoses and Services from the 
     prior insurance Purchase. The diagnoses should also be pointed 
     to the services as they were previously.

  3) At this time, it should only be available in the Purchase.

  4) This should only populate the Service and Dx codes - 
     the Treatment on the Purchase should not be impacted.

*/
