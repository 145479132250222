import { Select } from '@chiroup/components';
import { FeatureFlags, FormError, surveyIds } from '@chiroup/core';
import { MeContext } from '../../../contexts/me.context';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import useSurveys from '../../../hooks/useSurveys';
import useConsents from '../../practice-resources/hooks/useConsents';

type Props = {
  value?: string[];
  onChange: (val: string[]) => void;
  errors?: FormError;
  disabled?: boolean;
};

const PatientSurveySelect: React.FC<Props> = ({
  value,
  onChange,
  errors,
  disabled,
}) => {
  const { me, hasAccess } = useContext(MeContext);
  const { data } = useSurveys();
  const { data: consents } = useConsents();
  const [requestInfoItems, setRequestInfoItems] = useState<any[]>();

  //requestInsurance if for ehr and requestInsuraceCard is for essential
  const patientInfo = useMemo(() => {
    const patientInfoArr = [
      { name: 'Basic intake group', group: 'Groups', id: 'basicIntakeGroup' },
      {
        name: 'Detailed intake group',
        group: 'Groups',
        id: 'detailedIntakeGroup',
      },
      {
        name: 'Photo ID',
        group: 'Intake & history surveys',
        id: 'requestPhotoId',
      },
      {
        name: 'Patient demographics',
        group: 'Intake & history surveys',
        id: 'requestInfo',
      },
    ];

    if (hasAccess(FeatureFlags.billingInsurance)) {
      patientInfoArr.push({
        name: 'Insurance',
        group: 'Intake & history surveys',
        id: 'requestInsurance',
      });
    } else {
      patientInfoArr.push({
        name: 'Insurance card',
        group: 'Intake & history surveys',
        id: 'requestInsuranceCard',
      });
    }

    return patientInfoArr;
  }, [hasAccess]);

  useEffect(() => {
    if (data && consents) {
      const hiddenSurveys: string[] =
        me?.selectedClinic?.settings?.find(
          (clinic) => clinic.setting === 'Hidden surveys',
        )?.jsonValue || [];
      const filteredData: {
        name: string;
        group: string;
        id: string;
      }[] = data?.data?.filter((item) => !hiddenSurveys?.includes(item.id));

      let items = [...(patientInfo || []), ...(filteredData || [])];
      if (consents?.length) {
        items = [
          ...items,
          ...(consents?.map((consent) => ({
            name: consent.name,
            group: 'Consents & Acknowledgement surveys',
            id: `consent::${consent.url}`,
          })) || []),
        ];
      }

      if (hasAccess(FeatureFlags.ehr)) {
        items = items.filter((item) => item?.id !== surveyIds.ccs);
      }

      items.sort((a, b) => {
        if (a.group === 'Groups' && b.group !== 'Groups') {
          return -1;
        } else if (a.group !== 'Groups' && b.group === 'Groups') {
          return 1;
        }
        const groupComparison = a.group.localeCompare(b.group);
        if (groupComparison !== 0) {
          return groupComparison;
        }
        return a.name.localeCompare(b.name);
      });
      setRequestInfoItems(items);
    }
  }, [data, consents, me?.selectedClinic?.settings, patientInfo, hasAccess]);

  return (
    <Select
      name="surveys"
      className="col-span-2"
      label="Request info"
      value={value}
      onChange={onChange}
      errors={errors}
      options={(requestInfoItems || []).map((survey) => ({
        text: survey.name,
        value: survey.id,
        group: survey.group,
      }))}
      disabled={disabled}
      autocomplete
    />
  );
};

export default PatientSurveySelect;
