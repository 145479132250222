import {
  ClinicLocation,
  HasRoleOptions,
  Me,
  MeClinic,
  UserRoles,
  ValueOf,
} from '@chiroup/core';
import React from 'react';

export type SelectedLocationFullType = {
  ID?: number;
  name?: string;
  middleInitial?: string;
  suffix?: string;
  logo?: string;
  primaryName?: string;
  primaryLogo?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  phone?: string;
  fax?: string;
  timezone?: string;
  productTaxRate?: number;
  serviceTaxRate?: number;
  allLocations: ClinicLocation[];
  email?: string;
  site?: string;
  country?: string;
};

export const MeContext = React.createContext<{
  me: Me;
  updateMe: (key: keyof Me, value: ValueOf<Me>) => void;
  unselectClinic: () => void;
  selectClinic: (clinic: MeClinic) => void;
  selectLocation: (location: number) => void;
  hasAccess: (flag: string) => boolean;
  hasRole: (roles: UserRoles[], option?: HasRoleOptions) => boolean;
  activeLocations: ClinicLocation[];
  selectedLocationFull: SelectedLocationFullType;
  sessionId: string;
}>({
  me: {
    ID: '',
    isProvider: false,
    title: '',
    degree: '',
    fname: '',
    middleInitial: '',
    lname: '',
    suffix: '',
    name: '',
    email: '',
    phone: '',
    surveyOptOut: false,
    mfa: false,
    clinics: [],
    profileImage: null,
    dashboardData: {
      knowledgebase: [],
      videos: [],
      walkthroughs: [],
    },
  },
  updateMe: () => {},
  unselectClinic: () => {},
  selectClinic: () => {},
  selectLocation: () => {},
  hasAccess: () => {
    return false;
  },
  hasRole: () => {
    return false;
  },
  activeLocations: [],
  selectedLocationFull: {} as {
    ID: number | undefined;
    name: string | undefined;
    logo: string | undefined;
    primaryName: string | undefined;
    primaryLogo: string | undefined;
    address1: string | undefined;
    address2: string | undefined;
    city: string | undefined;
    state: string | undefined;
    country: string | undefined;
    zip: string | undefined;
    phone: string | undefined;
    fax: string | undefined;
    timezone: string | undefined;
    productTaxRate?: number;
    serviceTaxRate?: number;
    allLocations: ClinicLocation[];
    email: string | undefined;
    site: string | undefined;
  },
  sessionId: '',
});
