import {
  AddCodingDetails,
  Button,
  ButtonColors,
  Input,
  TrivialTooltip,
} from '@chiroup/components';
import {
  CodeSets,
  PatientTransactionItemType,
  TidyInputValue,
  classNames,
} from '@chiroup/core';
import React, { useContext, useEffect, useId, useState } from 'react';
import useDatabaseItem from '../../../../../settings/database/useDatabaseItem';
import { useSortable } from '@dnd-kit/sortable';
import { MeContext } from '../../../../../../contexts/me.context';
import { Transition } from '@headlessui/react';

type Props = {
  service: Partial<PatientTransactionItemType> & { selectedCode?: string };
  onChangeValue?: (
    index: number,
    val: PatientTransactionItemType | null,
  ) => void;
  index: number;
  readonly?: boolean;
  isBillingStarted?: boolean;
};

const CodeEncounterService: React.FC<Props> = ({
  service,
  onChangeValue,
  index,
  readonly = false,
  isBillingStarted = false,
}) => {
  const uniqueComponentKey = useId();
  // const { data: transaction } = useContext(TransactionContext);
  const { me } = useContext(MeContext);
  const { attributes, listeners, setNodeRef, isDragging, isOver, active } =
    useSortable({
      id: String(service.code),
      data: {
        type: 'service',
      },
      transition: {
        duration: 150,
        easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
      },
    });
  const { isSaving, save } = useDatabaseItem({
    instanceKey: 'billing-codes',
    id: 'add',
  });
  const [amountThere, setAmountThere] = React.useState<boolean>(
    Boolean(service && (service.id || (service as any).ID) && service.amount),
  );

  const modifierInputClassName = 'px-2 py-1',
    modifierClassName = 'w-12';

  useEffect(() => {
    if (!readonly) return;
    setAmountThere(
      Boolean(
        service &&
          (service.id || (service as any).ID) &&
          service.amount !== '0.00',
      ),
    );
  }, [service, readonly]);

  const onPromote = (e: any) => {
    const diagnoses = [...(service.diagnoses || [])];
    diagnoses.push(e);
    const nobj: any = { ...service };
    nobj.diagnoses = diagnoses;
    onChangeValue?.(index, nobj);
  };

  const updateValue = (e: any, prop: string) => {
    const nobj: any = { ...service };
    if (prop === 'diagnoses') {
      nobj[prop] = e;
    } else if (prop === 'amount') {
      nobj[prop] = TidyInputValue.Currency(e);
    } else if (prop === 'units') {
      nobj[prop] = Number.parseInt(e);
    } else {
      nobj[prop] = TidyInputValue.BillingCodeModifier(e);
    }
    onChangeValue?.(index, nobj);
  };

  /**
   * Making this a string is more user-friendly.
   */
  if (typeof service.amount === 'number') {
    service.amount = '' + service.amount.toFixed(2);
  }
  if (typeof service.units !== 'number') {
    service.units = 1;
  }

  const handleDeleteService = (e: any) => {
    if (readonly) return;
    onChangeValue?.(index, null);
  };

  const rememberService = async () => {
    let resp: any = null;
    try {
      resp = await save({
        codeSet: service.codeSet,
        code: service.code,
        codeShortcut: null,
        description: service.description,
        billedAmount: service.amount,
        defaultModifiers: [
          service.modifier1,
          service.modifier2,
          service.modifier3,
          service.modifier4,
        ],
      });
      const newObj: any = { ...service };
      ['ID', 'clinicID', 'codeSet', 'code', 'description'].forEach((prop) => {
        newObj[prop] = resp[prop];
      });
      newObj.amount = resp.billedAmount;
      newObj.modifier1 = resp.defaultModifiers?.[0] || null;
      newObj.modifier2 = resp.defaultModifiers?.[1] || null;
      newObj.modifier3 = resp.defaultModifiers?.[2] || null;
      newObj.modifier4 = resp.defaultModifiers?.[3] || null;

      onChangeValue?.(index, newObj);
    } catch (e) {
      console.error({ e });
    }
  };
  const [isShowing, setIsShowing] = useState(false);

  return (
    <div
      key={uniqueComponentKey}
      className={classNames(
        readonly || service?.locked ? 'cursor-not-allowed' : '',
        'relative rounded-md border border-gray-300 ring-4 ring-offset-1 bg-white mb-3',
        isDragging ? 'opacity-50' : '',
        isOver && active?.data.current?.type === 'diagnosis'
          ? isBillingStarted
            ? 'ring-accent-600'
            : 'ring-primary-600'
          : 'ring-transparent',
      )}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      onClick={
        readonly
          ? () => {
              setIsShowing(true);
              setTimeout(() => {
                setIsShowing(false);
              }, 300);
            }
          : undefined
      }
    >
      {readonly && (
        <Transition
          className="absolute bottom-1 right-2 text-gray-300 font-medium text-2xl"
          show={isShowing}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-250"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {`Service is ${
            service?.locked ? 'locked' : readonly ? 'readonly' : ''
          }.`}
        </Transition>
      )}
      <div className="rounded-t-md px-4 sm:grid sm:grid-cols-6 sm:gap-2 sm:px-0 bg-white text-gray-900 p-2 border-b border-gray-300">
        <div className="ml-2 text-sm font-medium leading-6 sm:col-span-1">
          {service.code?.trim()}
          {service.code &&
          service.selectedCode &&
          service.code !== service.selectedCode ? (
            <TrivialTooltip
              type="info"
              text={[
                `The custom code and description associated with the primary`,
                `payor are being displayed instead of those for the user-selected`,
                `code ${service.selectedCode}.`,
              ].join(' ')}
            />
          ) : null}
        </div>
        <div className="mt-1 flex text-sm leading-6 sm:col-span-5 sm:mt-0 mr-4 items-center">
          <span className="flex-grow">{service.description}</span>
          <span className="ml-4 flex-shrink-0 text-gray-900">
            <Input
              name="units"
              label=""
              value={service.units}
              onChange={(e) => {
                updateValue(e, 'units');
              }}
              type="number"
              min="1"
              max="100"
              inputTitle="The number of units of this service performed."
              disabled={readonly}
              inputClassName="p-0 pl-4"
            />
          </span>
          {!readonly && (
            <span
              className="ml-4 flex-shrink-0 cursor-pointer text-gray-500 hover:text-gray-900"
              onClick={handleDeleteService}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                />
              </svg>
            </span>
          )}
        </div>
      </div>
      <div className="flex flex-row mx-2">
        <div>
          <Input
            name="amount"
            label="Billed Amount"
            value={service.amount}
            onChange={(e) => {
              updateValue(e, 'amount');
            }}
            disabled={readonly || amountThere}
            inputTitle="The amount billed for this service. A value is required."
            className="w-24"
            inputClassName="px-2 py-1"
          />
        </div>
        <div className="ml-4 mt-2 text-sm">
          <div>Modifiers</div>
          <div className="flex flex-row space-x-2">
            <div>
              <Input
                label=""
                className={modifierClassName}
                inputClassName={modifierInputClassName}
                name="modifier1"
                value={service.modifier1}
                onChange={(e) => {
                  updateValue(e, 'modifier1');
                }}
                disabled={readonly}
              />
            </div>
            <div>
              <Input
                label=""
                className={modifierClassName}
                inputClassName={modifierInputClassName}
                name="modifier2"
                value={service.modifier2}
                onChange={(e) => {
                  updateValue(e, 'modifier2');
                }}
                disabled={readonly}
              />
            </div>
            <div>
              <Input
                label=""
                className={modifierClassName}
                inputClassName={modifierInputClassName}
                name="modifier3"
                value={service.modifier3}
                onChange={(e) => {
                  updateValue(e, 'modifier3');
                }}
                disabled={readonly}
              />
            </div>
            <div>
              <Input
                label=""
                className={modifierClassName}
                inputClassName={modifierInputClassName}
                name="modifier4"
                onChange={(e) => {
                  updateValue(e, 'modifier4');
                }}
                value={service.modifier4}
                disabled={readonly}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4 px-2">
        {service?.diagnoses?.length === 0 && readonly ? (
          <div className="text-sm text-gray-400">
            <cite>No assigned diagnoses!</cite>
          </div>
        ) : (
          <h3
            className={
              isBillingStarted ? 'text-accent-600' : 'text-primary-600'
            }
          >
            {(service?.diagnoses?.length || 0) === 1
              ? `Diagnosis`
              : `Diagnoses`}
          </h3>
        )}
      </div>{' '}
      <div className="px-2 cursor-default">
        {service &&
          service.diagnoses &&
          service.diagnoses.length > 0 &&
          service.diagnoses.map((diag, i) => (
            <div key={i} className="">
              <dl className="divide-y divide-gray-100">
                <div className="px-0 sm:grid sm:grid-cols-12 sm:gap-1 sm:px-0">
                  <dt className="cursor-default ml-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-900">
                    {diag.code}
                  </dt>
                  <dd className="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-9 sm:mt-0 mr-2">
                    <span className="flex-grow">{diag.description}</span>
                    {!readonly && (
                      <span
                        className=" cursor-pointer ml-4 flex-shrink-0"
                        onClick={(e: any) => {
                          if (readonly) return;
                          const items = [...(service.diagnoses || [])];
                          items.splice(i, 1);
                          updateValue(items, 'diagnoses');
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.0}
                          stroke="currentColor"
                          className="w-4 h-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                          />
                        </svg>
                      </span>
                    )}
                  </dd>
                </div>
              </dl>
            </div>
          ))}
      </div>
      {!readonly && (service?.diagnoses?.length || 0) < 4 ? (
        <div>
          <AddCodingDetails
            value={service}
            valueProp="diagnoses"
            codeSet={[CodeSets.DIAGNOSTIC, CodeSets.ICD10CM]}
            noCodesMessage="No diagnoses have been associated with this service."
            noneText="Type to search for diagnostic codes..."
            onPromote={onPromote}
            promoteTitle="Add this diagnosis to the service."
            repeatTitle="This diagnosis is already associated with the service."
            clinicId={me?.selectedClinic?.ID as number}
            isBillingStarted={isBillingStarted}
          />
        </div>
      ) : (
        <div className="p-0 -m-2">&nbsp;</div>
      )}
      {/* <div>
        <pre>
          {JSON.stringify(
            {
              service,
              isSaving,
              editBilledAmount,
            },
            null,
            2
          )}
        </pre>
      </div> */}
      {readonly && <br />}
      {!amountThere && service.amount && !readonly && (
        <div className="flex justify-end m-4 cursor-pointer">
          {' '}
          <Button
            text="Remember"
            onClick={rememberService}
            tooltip="This service code is not currently saved in your clinic-specific billing codes. Click the button to save this as a new custom code."
            tooltipClassName="w-96 text-left text-sm"
            loading={isSaving}
            color={
              isBillingStarted ? ButtonColors.accent : ButtonColors.primary
            }
          />
        </div>
      )}
    </div>
  );
};

export default CodeEncounterService;
