import dayjs, { Dayjs } from 'dayjs';

export const convertToMS = (
  time: number | string | Dayjs,
  startOrEnd?: 'start' | 'end',
): number => {
  if (!time) {
    return 0;
  } else if (typeof time === 'string' && startOrEnd && startOrEnd === 'start') {
    return dayjs(time).startOf('day').valueOf();
  } else if (typeof time === 'string' && startOrEnd && startOrEnd === 'end') {
    return dayjs(time).endOf('day').valueOf();
  } else if (typeof time === 'string') {
    return dayjs(time).valueOf();
  } else if (typeof time === 'object') {
    return time.valueOf();
  }
  return time > 9999999999 ? time : time * 1000;
};
