import { GLOBAL_MANY_IDS_SEP, Supplement } from '@chiroup/core';
import { ChiroUpAPI } from '@chiroup/client-core';
import { CustomizationListItem } from '../components/clinical-skills/CustomizationListItem.type';

const supplementService = () => {
  const list = async (
    clinicId = -1,
    omitOnline = false,
    ids: number[] = [],
  ): Promise<{
    data: CustomizationListItem[];
    itemTaxHash: { [key: string]: boolean };
    skip: number;
  }> => {
    return ChiroUpAPI.get('api', `/${clinicId}/supplements`, {
      queryParams: {
        omitOnline,
        ids,
      },
    });
  };

  const add = async (
    clinicId: number,
    body: Partial<Supplement>,
  ): Promise<Supplement> => {
    return ChiroUpAPI.post('api', `/${clinicId}/supplements`, {
      body,
    });
  };

  const findOne = async (
    clinicId = -1,
    id: string | number,
  ): Promise<Supplement> => {
    return ChiroUpAPI.get('api', `/${clinicId}/supplements/${id}`, {});
  };

  const getMany = async (clinicId = -1, ids: number[]): Promise<Supplement> => {
    return ChiroUpAPI.get(
      'api',
      `/${clinicId}/supplements/${'' + ids.join(GLOBAL_MANY_IDS_SEP)}`,
      {},
    );
  };

  const save = async (
    clinicId = -1,
    id: string | number,
    body: Partial<Supplement>,
  ): Promise<Supplement> => {
    const res = await ChiroUpAPI.patch(
      'api',
      `/${clinicId}/supplements/${id}`,
      {
        body,
      },
    );
    const item = res;
    // if (item.tags?.length) {
    //   item.tags = item.tags.map((tag: { tag: string }) => tag.tag);
    // }
    return item;
  };
  const canBeTaxed = async (
    clinicId = -1,
  ): Promise<{
    treatmentsItemTaxHash: { [key: string]: boolean };
    supplementsItemTaxHash: { [key: string]: boolean };
  }> => {
    return ChiroUpAPI.get('api', `/settings/${clinicId}/canBeTaxed`, {});
  };

  return {
    list,
    add,
    findOne,
    save,
    getMany,
    canBeTaxed,
  };
};

export default supplementService();
