import { Patient } from '@chiroup/core';
import { useDebounce } from '@chiroup/hooks';
import { useContext, useState } from 'react';
import { QueryFunctionContext, useInfiniteQuery } from 'react-query';
import { MeContext } from '../../../contexts/me.context';
import patientService from '../../../services/patient.service';

const getPatientsQuery = (clinicId = -1, showDeceased = true) => {
  return async (context: QueryFunctionContext) => {
    const searchTerm = context.queryKey[1] as string;
    const search = searchTerm || '';
    return patientService.list(
      { skip: context.pageParam, search, showDeceased },
      clinicId,
    );
  };
};

const usePatients = (showDeceased = true) => {
  const meContext = useContext(MeContext);
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearch = useDebounce(searchQuery);
  const {
    status,
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery<{ data: Patient[]; skip: number }>(
    ['patients', debouncedSearch],
    getPatientsQuery(meContext?.me.selectedClinic?.ID, showDeceased),
    {
      getNextPageParam: (lastGroup) => {
        if (!lastGroup) return;
        return lastGroup.data?.length === 25 ? lastGroup?.skip : undefined;
      },
      refetchOnWindowFocus: false,
    },
  );

  const onSearch = (params: string) => {
    setSearchQuery(params);
  };

  return {
    status,
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
    onSearch,
    searchQuery,
    setSearchQuery,
  };
};

export default usePatients;
