import dayjs from 'dayjs';
import React from 'react';
import Datepicker from 'react-tailwindcss-datepicker';
import { DateType, Configs } from 'react-tailwindcss-datepicker/dist/types';

type Props = {
  start: string;
  end: string;
  onChange: (
    newValue: {
      startDate: DateType;
      endDate: DateType;
    } | null,
  ) => void;
  shortcuts?: Configs['shortcuts'];
  disabled?: boolean;
  direction?: 'up' | 'down';
};

const DateRangePicker: React.FC<Props> = ({
  start,
  end,
  onChange: handleValueChange,
  shortcuts = {},
  disabled = false,
  direction,
}) => {
  return (
    <div className="flex h-full items-center">
      <Datepicker
        value={{
          startDate: start ? dayjs(start).toDate() : null,
          endDate: end ? dayjs(end).toDate() : null,
        }}
        onChange={handleValueChange}
        disabled={disabled}
        showShortcuts={true}
        containerClassName="z-30"
        inputClassName="rounded-none py-0 pl-0 pr-0 border-0 text-center flex items-center justify-center w-44 max-w-96 focus:ring-0 cursor-pointer bg-transparent font-normal text-sm"
        popoverDirection={direction}
        displayFormat="M/D/YYYY"
        separator="-"
        toggleIcon={() => null}
        toggleClassName="hidden"
        configs={{
          shortcuts,
        }}
      />
    </div>
  );
};

export default DateRangePicker;
