import { FormError } from '@chiroup/core';
import dayjs, { Dayjs, isDayjs } from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React, { useEffect, useMemo, useState } from 'react';
import Datepicker from 'react-tailwindcss-datepicker';
import { FieldErrors } from './FieldErrors';
import Select from 'intergalactic/select';
import Input from 'intergalactic/input';

dayjs.extend(utc);
dayjs.extend(tz);

const sortTimeIncrements = (
  timeArray: {
    text: string;
    value: string;
  }[],
) => {
  // Define a function to convert time strings to minutes since midnight
  function timeToMinutes(time: string) {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  }

  // Sort the timeArray based on the minutes since midnight
  return timeArray.sort((time1, time2) => {
    const minutes1 = timeToMinutes(time1.value);
    const minutes2 = timeToMinutes(time2.value);

    // Ensure that times before 6am show up after 11:45pm in the list...
    if (minutes1 < 360) {
      return minutes1 + 1440 - minutes2;
    }
    if (minutes2 < 360) {
      return minutes1 - (minutes2 + 1440);
    }

    return minutes1 - minutes2;
  });
};

type TimeOption = {
  text: string;
  value: string;
};

function roundToNearestInterval(
  date: dayjs.Dayjs,
  interval: number,
): dayjs.Dayjs {
  const minutes = date.minute();
  const remainder = minutes % interval;
  const nextInterval = interval - remainder;

  return date.add(nextInterval, 'minute').startOf('minute');
}

export const getNearest = (timezone: string) => {
  const now = dayjs().tz(timezone);
  const roundedNow = roundToNearestInterval(now, 15);
  const roundedNowUnix = roundedNow.valueOf();
  return roundedNowUnix;
};
export const getNearestTime = (day: string) => {
  const currentTime = dayjs();
  const start = dayjs(day, 'YYYY-MM-DD');
  const combinedTime = start
    .hour(currentTime.hour())
    .minute(currentTime.minute())
    .second(0)
    .millisecond(0);

  const roundedStart = roundToNearestInterval(combinedTime, 15);

  return roundedStart.valueOf();
};

function formatAmPm(time: Date): string {
  const hours = time.getHours();
  const minutes = time.getMinutes();
  const hh = hours % 12 || 12;
  const mm = minutes < 10 ? '0' + minutes : minutes;
  const amPm = hours < 12 ? 'am' : 'pm';
  return `${hh}:${mm}${amPm}`;
}

export function generateTimeOptions(
  startTime: string,
  endTime: string,
  interval: number,
): TimeOption[] {
  function roundToNearestInterval(time: Date, interval: number): Date {
    const minutes = Math.round(time.getMinutes() / interval) * interval;
    time.setMinutes(minutes);
    time.setSeconds(0);
    time.setMilliseconds(0);
    return time;
  }

  function formatTime(time: Date): string {
    const hours = time.getHours();
    const minutes = time.getMinutes();
    const hh = hours < 10 ? '0' + hours : hours;
    const mm = minutes < 10 ? '0' + minutes : minutes;
    return `${hh}:${mm}`;
  }

  const startDate = new Date(`1970-01-01T${startTime}:00`);
  const endDate = new Date(`1970-01-01T${endTime}:00`);

  const roundedStartDate = roundToNearestInterval(startDate, interval);
  const timeOptions: TimeOption[] = [];

  while (roundedStartDate <= endDate) {
    timeOptions.push({
      text: formatAmPm(roundedStartDate),
      value: formatTime(roundedStartDate),
    });
    roundedStartDate.setMinutes(roundedStartDate.getMinutes() + interval);
  }
  return sortTimeIncrements(timeOptions);
}

export const getMilitaryTime = (timeString: string): string => {
  const [time, modifier] = timeString.split(/(am|pm)/i);
  let [hours, minutes] = time.split(':').map(Number);

  if (modifier.toLowerCase() === 'pm' && hours < 12) {
    hours += 12;
  }
  if (modifier.toLowerCase() === 'am' && hours === 12) {
    hours = 0;
  }

  const hh = hours < 10 ? `0${hours}` : hours.toString();
  const mm = minutes < 10 ? `0${minutes}` : minutes.toString();

  return `${hh}:${mm}`;
};

type Props = {
  label?: string;
  timeLabel?: string;
  value?: Dayjs;
  onChange: (val: Dayjs) => void;
  errors?: FormError;
  timezone: string;
  localErrors: string;
  setLocalErrors: React.Dispatch<React.SetStateAction<string>>;
};

export const DateTime: React.FC<Props> = ({
  label = 'Date',
  value,
  onChange,
  errors,
  timezone,
  localErrors,
  setLocalErrors,
}) => {
  const [localTimeValue, setLocalTimeValue] = useState<string>('');
  useEffect(() => {
    if (value && !localTimeValue?.length) {
      setLocalTimeValue(dayjs(value).format('h:mmA').toLowerCase());
    }
  }, [localTimeValue, value]);

  useEffect(() => {
    if (localTimeValue?.length) {
      const timeRegex = /^(1[0-2]|0?[1-9]):([0-5][0-9])(am|pm)$/i;
      if (!timeRegex.test(localTimeValue)) {
        setLocalErrors('Invalid time format. Please use HH:MM am/pm');
      } else {
        setLocalErrors('');
      }
    }
  }, [localTimeValue, setLocalErrors]);

  const theDate = useMemo(() => {
    const date = isDayjs(value)
      ? value?.format('YYYY-MM-DD')
      : dayjs(value).format('YYYY-MM-DD');
    return { startDate: date, endDate: date };
  }, [value]);

  const options = useMemo(() => {
    return generateTimeOptions('00:00', '23:45', 15);
  }, []);

  return (
    <div className="flex gap-8">
      <div>
        <div className="flex flex-col flex-wrap">
          <p className="mb-1 block text-sm font-medium leading-5 text-gray-900 sm:mt-px sm:pt-2 dark:text-darkGray-200">
            Time *
          </p>
          <Select
            interaction="focus"
            size={'l'}
            onChange={(val: any) => {
              setLocalTimeValue(val);
              const neededTime = getMilitaryTime(val);
              onChange(
                dayjs.tz(`${theDate.startDate} ${neededTime}`, timezone),
              );
            }}
            value={localTimeValue}
          >
            <Select.Trigger tag={Input}>
              {() => (
                <Input.Value
                  value={localTimeValue}
                  onChange={(val: any) => {
                    setLocalTimeValue(val);
                    const neededTime = getMilitaryTime(val);
                    onChange(
                      dayjs.tz(`${theDate.startDate} ${neededTime}`, timezone),
                    );
                  }}
                  id="release-time-picker"
                  onKeyDown={(e: any) => {
                    if (e.key === 'Enter') {
                      const neededTime = getMilitaryTime(e.target.value);
                      onChange(
                        dayjs.tz(
                          `${theDate.startDate} ${neededTime}`,
                          timezone,
                        ),
                      );
                    }
                  }}
                />
              )}
            </Select.Trigger>
            <Select.Menu>
              {options.map((option) => (
                <Select.Option value={option.text} key={option.value}>
                  {option.text}
                </Select.Option>
              ))}
            </Select.Menu>
          </Select>
        </div>
        <FieldErrors errors={{ message: localErrors }} />
      </div>
      <div className="flex flex-col">
        <label
          htmlFor="date"
          className="block text-sm font-medium leading-5 text-gray-900 sm:mt-px sm:pt-2 dark:text-darkGray-200"
        >
          {label}
        </label>
        <div id="date">
          <Datepicker
            value={theDate as any}
            onChange={(val: any) => {
              try {
                onChange(
                  dayjs.tz(
                    `${val.startDate} ${getMilitaryTime(localTimeValue)}`,
                    timezone,
                  ),
                );
              } catch (e: any) {
                // Noop: Just don't want to kill the interpreter.
              }
            }}
            asSingle
            useRange={false}
            placeholder={'MM/DD/YYYY'}
            displayFormat={'MM/DD/YYYY'}
            primaryColor={'green'}
            inputClassName="z-50 border border-gray-300 shadow-sm rounded-md mt-1 focus:outline-none focus:ring-none focus:ring-primary-500 focus:border-transparent pl-2 dark:bg-darkGray-600 dark:text-darkGray-200"
            readOnly={true}
          />
        </div>
      </div>

      <FieldErrors errors={errors} />
    </div>
  );
};
