import {
  PatientTransactionItemType,
  TransactionItemSubtypeEnum,
} from '../types';
import { subtotalFromItemsBeforePercentageDiscounts } from './balanceFromItems';

export const findNewDiscount = (
  items: PatientTransactionItemType[],
  serviceTaxRate: number,
  productTaxRate: number,
) => {
  const hasService = items?.some(
    (item: PatientTransactionItemType) =>
      item.subtype === TransactionItemSubtypeEnum.Service,
  );
  const taxRate = hasService ? serviceTaxRate : productTaxRate;
  const percentDiscountApplied = items?.find(
    (item) =>
      item.subtype === 'Adjustment' &&
      item?.adjustment?.structure === '% Discount',
  ) as PatientTransactionItemType;
  const percentOff = percentDiscountApplied?.adjustment?.percentOff;
  const filteredOutPercentOff = items?.filter(
    (item) =>
      item.subtype !== 'Adjustment' &&
      item?.adjustment?.structure !== '% Discount',
  );

  const subtotal = subtotalFromItemsBeforePercentageDiscounts({
    items: filteredOutPercentOff || [],
    taxRate,
  });

  const newDiscount = !percentOff
    ? 0
    : (Number(subtotal) * Number(percentOff)) / 100;

  const newItems = [
    ...filteredOutPercentOff,
    { ...percentDiscountApplied, amount: newDiscount },
  ];
  return newItems;
};
