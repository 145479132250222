import { GLOBAL_MANY_IDS_SEP } from '@chiroup/core';
import { ChiroUpAPI } from '@chiroup/client-core';

const databaseServiceFactory = () => {
  const list = async <T>({
    clinicId,
    database,
    page = 1,
    limit = 10,
    search,
    allCaseTypes = false,
    showDeleted = false,
  }: {
    clinicId: number;
    database: string;
    page?: number;
    limit?: number;
    search?: string;
    allCaseTypes?: boolean;
    showDeleted?: boolean;
  }): Promise<{
    data: T[];
    skip: number;
    count: number;
  }> => {
    const params: {
      clinicId: number;
      database: string;
      page?: number;
      limit?: number;
      search?: string;
      allCaseTypes?: boolean;
      showDeleted?: boolean;
    } = {
      clinicId,
      database,
      page,
      limit,
      search,
    };
    if (allCaseTypes) {
      params.allCaseTypes = allCaseTypes;
    }
    if (showDeleted) {
      params.showDeleted = showDeleted;
    }
    return ChiroUpAPI.get('api', `/settings/${clinicId}/database/${database}`, {
      queryParams: params,
    });
  };

  const get = async <T>({
    clinicId,
    database,
    id,
  }: {
    clinicId: number;
    database: string;
    id: number;
  }): Promise<T> => {
    /**
     * We were getting a REST call to retrieve -1, which is useless so,
     * here's a short-cut.
     */
    if (id < 0) {
      return {} as T;
    }
    return ChiroUpAPI.get(
      'api',
      `/settings/${clinicId}/database/${database}/${id}`,
      {},
    );
  };

  /**
   * This implies the billing code data set, so no database is required, just
   * the clinicId and the code.
   *
   * Adding a parameter of 'payors' that is true will return the code with
   * any payors attached to it. Useful for adding modifiers.
   *
   * @param param
   * @returns
   */
  const getCode = async <T>({
    clinicId,
    code,
    payors,
  }: {
    clinicId: number | undefined;
    code: string | undefined;
    payors?: boolean;
  }): Promise<T> => {
    if (!clinicId || !code) {
      throw new Error('A clinic id and code are required.');
    }
    try {
      return ChiroUpAPI.get(
        'api',
        `/settings/${clinicId}/codes/${code}${
          typeof payors === 'boolean' ? `?payors=${payors}` : ''
        }`,
        {},
      );
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  /**
   * This implies the billing code data set, so no database is required, just
   * the clinicId and the code.
   *
   * @param param
   * @returns
   */
  const getCodes = async <T>({
    clinicId,
    codes,
    payors,
  }: {
    clinicId: number | undefined;
    codes: string[] | undefined;
    payors?: boolean;
  }): Promise<T> => {
    if (!clinicId || !codes || (!!codes && codes.length === 0)) {
      throw new Error(
        `A clinic (${clinicId}) id and code (${codes}) are required.`,
      );
    }
    try {
      return ChiroUpAPI.get(
        'api',
        `/settings/${clinicId}/codes/${codes.join(GLOBAL_MANY_IDS_SEP)}${
          typeof payors === 'boolean' ? `?payors=${payors}` : ''
        }`,
        {},
      );
    } catch (e: any) {
      console.error(e);
      throw e;
    }
  };

  const update = async <T>({
    clinicId,
    database,
    item,
    force = false,
  }: {
    clinicId: number;
    database: string;
    item: T;
    force: boolean;
  }): Promise<T> => {
    const itemId = (item as any).ID || (item as any).id;
    return ChiroUpAPI.put(
      'api',
      `/settings/${clinicId}/database/${database}/${itemId}`,
      {
        body: { item, force },
      },
    );
  };

  const create = async <T>({
    clinicId,
    database,
    item,
    force = false,
  }: {
    clinicId: number;
    database: string;
    item: T;
    force: boolean;
  }): Promise<T> => {
    return ChiroUpAPI.post(
      'api',
      `/settings/${clinicId}/database/${database}`,
      {
        body: { item, force },
      },
    );
  };

  const save = async <T>({
    clinicId,
    database,
    item,
    force = false,
  }: {
    clinicId: number;
    database: string;
    item: T;
    force: boolean;
  }): Promise<T> => {
    return (item as any).ID || (item as any).id
      ? update({ clinicId, database, item, force })
      : create({ clinicId, database, item, force });
  };

  const del = async <T>({
    clinicId,
    database,
    id,
  }: {
    clinicId: number;
    database: string;
    id: number;
  }): Promise<T> => {
    return ChiroUpAPI.del(
      'api',
      `/settings/${clinicId}/database/${database}/${id}`,
      {},
    );
  };

  return {
    list,
    get,
    save,
    del,
    getCode,
    getCodes,
  };
};

export const databaseService = databaseServiceFactory();
