import { useContext, useId, useMemo } from 'react';
import { useQuery } from 'react-query';
import { MeContext } from '../../../../../contexts/me.context';
import { Insurance, PatientInsuranceTypes } from '@chiroup/core';
import patientBillingService from '../../../../../services/patientBilling.service';

const countByTypeAndActiveStatus = (
  insuranceList: Insurance[],
  type: PatientInsuranceTypes,
) => {
  return insuranceList?.filter(
    (item) => item.type === type && item.active === 1,
  ).length;
};

const useInsuranceList = ({
  patientId,
  providerId,
}: {
  patientId: string;
  providerId?: string;
}) => {
  const id = useId();
  const meContext = useContext(MeContext);
  const { data, isFetching, refetch } = useQuery(
    ['insuranceList', patientId, id],
    () =>
      patientBillingService.listPatientInsurance(
        meContext.me.selectedClinic?.ID || -1,
        patientId,
        providerId || '',
      ),
    {
      refetchOnWindowFocus: false,
    },
  );

  const hasMultiplePrimaryActive = useMemo(() => {
    return (
      countByTypeAndActiveStatus(
        data?.data || [],
        PatientInsuranceTypes.Primary,
      ) > 1
    );
  }, [data]);

  const hasMultipleSecondaryActive = useMemo(() => {
    return (
      countByTypeAndActiveStatus(
        data?.data || [],
        PatientInsuranceTypes.Secondary,
      ) > 1
    );
  }, [data]);

  return {
    isFetching,
    data,
    hasMultiplePrimaryActive,
    hasMultipleSecondaryActive,
    refetch,
  };
};

export default useInsuranceList;
