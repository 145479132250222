import Decimal from 'decimal.js';
import { createDecimal } from './createDecimal';
import { CalculationPayor } from './calculateResponsibilities';

export const calculatePayorResponsibilityPerServiceNew = ({
  payor,
}: {
  payor: CalculationPayor;
}): { code: string; amount: number }[] => {
  const coPayDecimal = createDecimal(payor.coPay || 0);
  const coInsuranceDecimal = createDecimal(payor.coInsurance || 0).div(100);
  const deductibleDecimal = createDecimal(payor.deductible || 0);

  let remainingDeductible = deductibleDecimal;
  let remainingCoPay = coPayDecimal;

  const results: { code: string; amount: number }[] = [];

  (payor.serviceAmounts as { code: string; amount: number }[]).forEach(
    (service) => {
      const key = service.code;
      const value = service.amount;

      const allowedAmountDecimal = createDecimal(value || 0);
      let responsibility = allowedAmountDecimal;

      if (remainingCoPay.greaterThan(0)) {
        responsibility = allowedAmountDecimal.sub(remainingCoPay).lessThan(0)
          ? createDecimal(0)
          : allowedAmountDecimal.sub(remainingCoPay);
        remainingCoPay = remainingCoPay.sub(allowedAmountDecimal);
      }

      if (remainingDeductible.greaterThan(0)) {
        const deductibleApplied = Decimal.min(
          responsibility,
          remainingDeductible,
        );
        responsibility = responsibility.sub(deductibleApplied);
        remainingDeductible = remainingDeductible.sub(deductibleApplied);
      }

      const coInsuranceAmount = responsibility.mul(coInsuranceDecimal);
      responsibility = responsibility.sub(coInsuranceAmount);

      responsibility = responsibility.lessThan(0)
        ? createDecimal(0)
        : responsibility;

      results.push({
        code: key,
        amount: responsibility.toNumber(),
      });
    },
  );

  return results;
};
