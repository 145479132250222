// type UserClinicRole = {
//   id: string;
//   name: string;
// };

import { ClinicLocation } from './Clinic.type';
import { Clinician, Plan, Prices, Subscription } from './Me.type';

export type UserClinic = {
  ID: number;
  address1: string;
  address2: string;
  billDate: string;
  bypassPaymentGateway: boolean;
  city: string;
  clinicians: Clinician[];
  country: string;
  email: string;
  facebook: string;
  fax: string;
  freeAccount: boolean;
  kioskID: string;
  language: string;
  location: string;
  logo: string;
  name: string;
  phone: string;
  plan: Plan;
  prices?: Prices;
  role: UserRoles[];
  settings?: {
    ID: number;
    section: string;
    setting: string;
    jsonValue: any;
  }[];
  site: string;
  state: string;
  subscription: Subscription;
  subscriptionAmount: number;
  subscriptionID: number;
  surveyOptOut: boolean;
  termsOfUse: string;
  theme: string;
  trialCount: number;
  twitter: string;
  updatingIcons: number;
  youtube: string;
  zip: string;
  locations: ClinicLocation[];
};

// Admin: the users who configure the system and manage the account
// Provider: the users who perform clinical services and are the rendering provider on claims
// Front Office (Formerly, Staff - so we're keeping that value): the users who are front office and administrative personnel
// Biller: the users who are responsible for billing tasks and managing claims
// Clinical Assistant: the users who are medical assistants who help perform basic clinical tasks
// Content & Database Editor: the users who are authorized to make edits to any of the clinical and billing databases
// Business Manager: the user(s) who perform bookkeeping and accounting tasks

export enum UserRoles {
  Admin = 'Admin',
  Provider = 'Provider',
  Staff = 'Staff',
  Biller = 'Biller',
  ClinicalAssistant = 'ClinicalAssistant',
  ContentDatabaseEditor = 'ContentDatabaseEditor',
  BusinessManager = 'BusinessManager',
}

export const USER_ROLE_DISPLAY = {
  Admin: 'Admin',
  Provider: 'Provider',
  Staff: 'Front Office',
  Biller: 'Biller',
  ClinicalAssistant: 'Clinical Assistant',
  ContentDatabaseEditor: 'Content & Database Editor',
  BusinessManager: 'Business Manager',
};

export const BASE_ROLES = [
  {
    value: UserRoles.Admin,
    text: USER_ROLE_DISPLAY[UserRoles.Admin],
  },
  {
    value: UserRoles.Provider,
    text: USER_ROLE_DISPLAY[UserRoles.Provider],
  },
  {
    value: UserRoles.Staff,
    text: USER_ROLE_DISPLAY[UserRoles.Staff],
  },
];

export enum HasRoleOptions {
  HasAllRoles = 'HasAllRoles',
  HasSingleRole = 'HasSingleRole',
}

export const ROLES = Object.entries(USER_ROLE_DISPLAY).map(([value, text]) => ({
  value,
  text,
}));

export type User = {
  ID: string;
  name: string;
  title: string;
  degree: string;
  fname: string;
  middleInitial?: string;
  lname: string;
  suffix?: string;
  email: string;
  phone: string;
  role: UserRoles[];
  signature?: string;
  status: string;
  surveyOptOut: boolean;
  enableOnlineBookings: boolean;
  mfa: boolean;
  clinics: UserClinic[];
  connectedToRealtime: boolean;
  searchStr: string;
  lastFirst: string;
  createDate?: string;
  lastLogin?: string;
  referral?: string;
  notes?: string;
  referralCode?: string;
  GraduationDate?: string;
  schoolName?: string;
  clinicName?: string;
  beta?: boolean;
  profileImage: string | null;
  billingProfileId?: number;
};

export const getUserDisplayName = (user: User) => {
  return `${user.lname}, ${user.fname} (${user?.email ?? '- no email -'})`;
};

export type RenderingProviderUser = User & {
  npi?: string;
  taxonomyCode?: string;
  taxIdNumber?: string;
  taxIdType?: string;
  upin?: string;
};
