import { useQuery } from 'react-query';
import clinicService from '../services/clinic.service';
import { Availability, Override, ScheduleDefaults } from '@chiroup/core';
import { useContext, useEffect, useState } from 'react';
import { MeContext } from '../contexts/me.context';

const getQuery = (
  clinicId?: number,
  locationId?: number,
  hasAccess?: boolean,
) => {
  return async () => {
    if (!clinicId || !locationId || !hasAccess) {
      return {
        schedule: ScheduleDefaults,
        scheduleOverrides: [],
      };
    }

    return clinicService.getClinicLocationSchedule(clinicId, locationId);
  };
};

type Props = {
  clinicId?: number;
  locationId?: number;
};

const useLocationAvailability = ({ clinicId, locationId }: Props) => {
  const [availability, setAvailability] =
    useState<Availability>(ScheduleDefaults);
  const { hasAccess } = useContext(MeContext);
  const [overrides, setOverrides] = useState<Override[]>([]);
  const { data, isFetching } = useQuery(
    ['locationSchedule', clinicId, locationId],
    getQuery(clinicId, locationId, hasAccess('scheduling')),
    {
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (data) {
      setAvailability(data.schedule);
      setOverrides(data.scheduleOverrides);
    }
  }, [data]);

  return {
    isFetching,
    availability,
    setAvailability,
    overrides,
    setOverrides,
  };
};

export default useLocationAvailability;
