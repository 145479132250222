import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useContext } from 'react';
import { ToastContext } from '../../contexts/toast.context';
import { STRING_BOOLEAN_HASH } from '../../../../../libs/core/src';
import { classNames } from '@chiroup/core';

type Props = {
  isOpen: boolean;
  children: React.ReactNode | React.ReactNode[];
  close?: () => void;
  className?: string;
  containerClassName?: string;
  initialFocus?: any;
  disableClickOutsideClose?: boolean;
  omitClasses?: string;
  addClasses?: string;
  isFullScreen?: boolean;
  surveyModal?: boolean;
};

const Modal: React.FC<Props> = ({
  isOpen,
  children,
  close,
  className = 'print:absolute print:top-0 print:left-0 print:border-none print:shadow-none inline-block align-bottom bg-white dark:bg-darkGray-900 rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full sm:p-6 print:hidden sm:max-w-lg',
  containerClassName = '',
  initialFocus,
  disableClickOutsideClose = false,
  omitClasses = '',
  addClasses = '',
  isFullScreen = false,
  surveyModal = false,
}) => {
  const modalSizeClasses = isFullScreen
    ? 'fixed inset-0 z-50 overflow-y-auto sm:p-12 p-2 bg-white print:absolute print:top-0 print:left-0 print:border-none print:shadow-none print:visible print:overflow-visible transform transition-all'
    : surveyModal
      ? 'w-screen px-4 inset-0 z-50 bg-white print:absolute print:top-0 print:left-0 print:border-none print:shadow-none print:visible print:overflow-visible transform transition-all'
      : className;

  const containerSizeClasses = isFullScreen
    ? 'flex items-center justify-center min-h-screen'
    : surveyModal
      ? 'flex items-center justify-center'
      : 'flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0';

  const klasses = modalSizeClasses.split(/\s+/);
  const omitClassesHash = omitClasses
    .split(/\s+/)
    .reduce((acc: STRING_BOOLEAN_HASH, klass) => {
      if (klass) acc[klass] = true;
      return acc;
    }, {});

  const childClasses = classNames(
    klasses.filter((klass) => !omitClassesHash[klass]).join(' '),
    addClasses,
  );

  const { isToast } = useContext(ToastContext);
  const handleClose = () => {
    if (disableClickOutsideClose || isToast) {
      return;
    } else {
      close?.();
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto print:overflow-y-visible"
        open={isOpen}
        onClose={handleClose}
        initialFocus={initialFocus}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="fixed inset-0 bg-black bg-opacity-25 print:hidden"
            onClick={() => close?.()}
          />
        </Transition.Child>
        <div className={[containerSizeClasses, containerClassName].join(' ')}>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className={childClasses}>{children}</div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
