import { useState, useEffect, useCallback } from 'react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { convertToMS } from '@chiroup/core';

dayjs.extend(timezone);

export const useTime = (zoom: number, timezone?: string) => {
  const [timeData, setTimeData] = useState({
    top: 0,
    time: dayjs().tz(timezone).format('h:mm A'),
    hour: dayjs().tz(timezone).hour(),
  });

  const updateTimeData = useCallback(() => {
    const currentTime = dayjs().tz(timezone);
    const currentHour = currentTime.hour();
    const currentMinute = currentTime.minute() + 1;
    const topPosition = (currentHour * (zoom * 30) + currentMinute) * 2;

    setTimeData({
      top: topPosition,
      time: currentTime.format('h:mm A'),
      hour: currentHour,
    });
  }, [timezone, zoom]);

  useEffect(() => {
    updateTimeData();

    const timeUntilNextMinute = convertToMS(60 - dayjs().tz(timezone).second());

    const initialTimeoutId = setTimeout(() => {
      updateTimeData();
      const intervalId = setInterval(() => {
        updateTimeData();
      }, 60 * 1000);

      return () => {
        clearInterval(intervalId);
      };
    }, timeUntilNextMinute);

    return () => {
      clearTimeout(initialTimeoutId);
    };
  }, [timezone, updateTimeData]);

  return timeData;
};
