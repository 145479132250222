import { ChiroUpAPI } from '@chiroup/client-core';
import { Me, SeminarSignupForm, UserSettingsBillingType } from '@chiroup/core';
import axios from 'axios';

const settingReference: { [key: string]: string } = {
  defaultToExercisePlans: 'Default to Creating Exercise Plans',
  displayCurrentAndUpcomingExercisesOnPatientApp:
    'Display current and upcoming exercises on patient app',
  // exportToPDFOnSaveUpdateEmail: 'Export to PDF on Save, Update and Email',
  generateHealthcareProviderReports: 'Generate healthcare provider reports',
  printExerciseChartCopyByDefault: 'Print exercise chart copy',
  showPrintGreenBoxOption: 'Print green box',
  showKeyExamFindings: 'Show key exam findings',
  goalPercent: 'Goal',
  inOfficeVisits: 'In office visits',
  exerciseSummary: 'Copy exercise summary',
  hideLetterheadOnChartCopy: 'Hide letterhead on chart copy',
  reEvalInterval: 'Re-evaluation Interval',
};

const userService = () => {
  const me = async (): Promise<Me> => {
    return await ChiroUpAPI.get('api', `/me`, {});
  };

  const forgotPassword = async (email: string) => {
    return ChiroUpAPI.post('api-noauth', '/forgot-password', {
      body: { email },
    });
  };

  const settings = async (params?: any) => {
    return ChiroUpAPI.get('api', `/settings/user/settings`, {
      queryParams: params,
    });
  };

  const saveUser = async (val: any) => {
    return ChiroUpAPI.put('api', `/settings/user`, {
      body: val,
    });
  };

  const downloadBAA = async (clinicId: number) => {
    const res = await ChiroUpAPI.get('api', `/users/${clinicId}/baa`, {});
    return res?.url;
  };

  const saveReportPreferences = async (val: any) => {
    const transformedValues = Object.keys(val).map((uiProp) => {
      return {
        value:
          typeof val[uiProp] === 'string'
            ? val[uiProp]
            : JSON.stringify(val[uiProp]),
        name: settingReference[uiProp],
        type: 'Condition Report',
      };
    });
    return ChiroUpAPI.put('api', `/settings/user/settings`, {
      body: transformedValues,
    });
  };
  const saveCustomSettings = async (val: any) => {
    return ChiroUpAPI.put('api', `/settings/user/settings`, {
      body: val,
    });
  };

  const leaveClinic = (clinicToLeave: number) => {
    return ChiroUpAPI.post('api', `/settings/${clinicToLeave}/leave`, {});
  };

  const changePassword = ({
    newPassword,
  }: {
    newPassword: string;
  }): Promise<any> => {
    return ChiroUpAPI.put('api', `/users/change-password`, {
      body: { newPassword },
    });
  };

  const toggleMFA = ({ status }: { status: boolean }): Promise<any> => {
    return ChiroUpAPI.put('api', `/mfa`, {
      body: { status },
    });
  };

  const checkExists = (email: string, referrer: string, val?: Partial<Me>) => {
    return ChiroUpAPI.post(
      'api-noauth',
      `/users/user/user-check-exists/${encodeEmail(email)}`,
      {
        body: { ...val, referrer },
      },
    );
  };

  const checkExistsAndValidate = (email: string) => {
    return ChiroUpAPI.get(
      'api-noauth',
      `/users/user/user-exists-validate/${encodeEmail(email)}`,
      {},
    );
  };

  const checkValidationCode = (email: string, validationCode: number) => {
    return ChiroUpAPI.get(
      'api-noauth',
      `/users/user/user-validate/${encodeEmail(email)}/${validationCode}`,
      {},
    );
  };

  const getJWTData = async (token: string) => {
    return ChiroUpAPI.get('api-noauth', `/users/user/user-seminar-data`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  const createSeminarUserClinic = async (
    token: string,
    values: Partial<SeminarSignupForm>,
  ) => {
    return ChiroUpAPI.post('api-noauth', `/users/user/user-seminar/create`, {
      headers: { Authorization: `Bearer ${token}` },
      body: values,
    });
  };

  /**
   * [2023-07-25.0803 by Brian] Per JG, we wait up to three seconds.
   * Added 61 milliseconds to compensate for the delay getting the
   * request started. Any failure is [almost] silently eaten and we
   * assume the email is valid.
   *
   * Reference:
   *    https://axios-http.com/docs/cancellation
   */
  const checkIfValidEmail = async (email: string) => {
    const controller = new AbortController();

    const axiosPromise = axios.get(
      `https://api.zerobounce.net/v2/validate?api_key=${
        import.meta.env.VITE_ZEROBOUNCE_KEY
      }&email=${email}`,
      { signal: controller.signal },
    );

    const localPromise = new Promise<{ data: { status: string } }>(
      (resolve, _) => {
        axiosPromise.then(
          (res) => {
            resolve(res);
          },
          () => {
            /**
             * I _know_. Not _exactly_ silent, but silent enough.
             */
            console.error(
              'REST call to zerobounce was unsuccessful or timed out. Assuming email is valid.',
            );
            /**
             * The status message does not appear to matter as long as _is not_
             * 'invalid'. That seems to be the only check any place this method
             * is called.
             */
            resolve({ data: { status: 'assumed-okay' } });
          },
        );
      },
    );

    setTimeout(() => {
      controller.abort();
    }, 3061);

    return localPromise;
  };

  const topicActivity = ({
    topic,
    clinicId,
    userId,
    data,
  }: {
    topic: string;
    clinicId?: number;
    userId?: string;
    data?: any;
  }): Promise<void> => {
    return ChiroUpAPI.post('api', `/${clinicId}/topic-activity`, {
      body: { topic, userId, data },
    });
  };

  const topicActivityDelete = ({
    topic,
    clinicId,
  }: {
    topic: string;
    clinicId?: number;
  }): Promise<void> => {
    return ChiroUpAPI.del('api', `/${clinicId}/topic-activity`, {
      body: { topic },
    });
  };

  const getSignature = async (): Promise<string> => {
    return ChiroUpAPI.get('api', `/users/user/signature`, {});
  };

  const getClinicianSignature = async (key: string): Promise<string> => {
    return ChiroUpAPI.get('api', `/users/user/signature?key=${key}`, {});
  };

  const getClinicianSignatureById = async (id: string): Promise<string> => {
    return ChiroUpAPI.get('api', `/users/user/signature?user=${id}`, {});
  };

  const deleteSignature = async (): Promise<void> => {
    return ChiroUpAPI.del('api', `/users/user/signature`, {});
  };

  const submitSignature = async (
    signature: any,
  ): Promise<{ url: string; key: string }> => {
    const resp = await ChiroUpAPI.post('api', `/users/user/signature`, {
      signature,
    });

    const form = new FormData();
    Object.entries(resp.fields).forEach(([key, val]) => {
      form.append(key, val as any);
    });
    form.append('file', signature);
    await fetch(resp.url, { method: 'POST', body: form });

    const signatureUrl: string = await getSignature();

    return {
      url: signatureUrl,
      key: resp?.fields?.key,
    };
  };

  const createBilling = (
    userBilling: Partial<UserSettingsBillingType>,
    clinicId?: number,
  ) => {
    return ChiroUpAPI.post('api', `/users/${clinicId}/user/billing`, {
      body: userBilling,
    });
  };

  const getBilling = (userId: string, clinicId?: number) => {
    return ChiroUpAPI.get(
      'api',
      `/users/${clinicId}/user/billing/${userId}`,
      {},
    );
  };

  const listBilling = (clinicId?: number) => {
    return ChiroUpAPI.get('api', `/users/${clinicId}/user/billing`, {});
  };

  const updateBilling = (
    userBilling: Partial<UserSettingsBillingType>,
    clinicId?: number,
  ) => {
    return ChiroUpAPI.put('api', `/users/${clinicId}/user/billing`, {
      body: userBilling,
    });
  };

  const getUserBillingProfiles = (
    userId: string,
    clinicId?: number,
    active = true,
  ) => {
    return ChiroUpAPI.get(
      'api',
      `/users/${clinicId}/user/billing-profile/${userId}`,
      {
        queryParams: { active },
      },
    );
  };

  return {
    changePassword,
    checkExists,
    checkIfValidEmail,
    createBilling,
    deleteSignature,
    downloadBAA,
    forgotPassword,
    getBilling,
    leaveClinic,
    me,
    saveReportPreferences,
    saveUser,
    settings,
    submitSignature,
    toggleMFA,
    topicActivity,
    topicActivityDelete,
    updateBilling,
    getSignature,
    getClinicianSignature,
    checkExistsAndValidate,
    checkValidationCode,
    getJWTData,
    createSeminarUserClinic,
    listBilling,
    getClinicianSignatureById,
    getUserBillingProfiles,
    saveCustomSettings,
  };
};

export default userService();

const encodeEmail = (email: string) => {
  return encodeURIComponent(email);
};
