import { HealthcareProvider } from '@chiroup/core';
import { useContext } from 'react';
import { QueryFunctionContext, useQuery, useQueryClient } from 'react-query';
import { MeContext } from '../contexts/me.context';
import healthcareProviderService from '../services/healthcareProvider.service';

const getQuery = (disableFetch: boolean, previousState: any) => {
  return async (context: QueryFunctionContext) => {
    if (disableFetch) {
      return { previousState };
    }
    const userId = context.queryKey[1] as string;
    const clinicId = context.queryKey[2] as string;
    if (!clinicId || !userId) {
      return {};
    }
    return healthcareProviderService.list(
      { limit: 1000 },
      userId,
      Number(clinicId),
    );
  };
};

const useHealthcareProvidersAll = (disableFetch = false) => {
  const { me } = useContext(MeContext);
  const queryClient = useQueryClient();

  const { data, isFetching, refetch } = useQuery<{
    data: HealthcareProvider[];
  }>(
    ['healthcareProviders', me.ID, me.selectedClinic?.ID],
    getQuery(disableFetch, getPreviousState(queryClient)),
    {
      refetchOnWindowFocus: false,
    },
  );

  const addProvider = (newProvider: Partial<HealthcareProvider>) => {
    queryClient.setQueryData('healthcareProviders', {
      data: [...(data?.data || []), newProvider],
    });
  };

  return {
    data,
    isFetching,
    refetch,
    addProvider,
  };
};

export default useHealthcareProvidersAll;

const getPreviousState = (queryClient: any) => {
  return queryClient.getQueryData('healthcareProviders');
};
